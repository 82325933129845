<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col
        class="d-flex align-center justify-center justify-md-start align-md-start mt-8 mb-md-4"
      >
        <FlagTitle title="Contexto e Conquista" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                Em 2016, foi iniciada pela Synergia/Renova a aplicação de um
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_violacoes_direitos' }"
                  >formulário para cadastro</router-link
                >
                das famílias atingidas pelo rompimento da barragem de Fundão em
                Mariana-MG. Foram inúmeras as insatisfações levantadas em
                pareceres críticos de diferentes instituições e da escuta dos
                atingidos e das atingidas, tais como questionamentos à falta de
                participação das pessoas atingidas nos processos decisórios de
                reparação; à longa duração do cadastramento; à linguagem muito
                técnica, sem uma explicação simplificada; ao formulário
                insuficiente, que não contemplava todas as perdas e danos e com
                inadequações aos perfis da população local.
              </p>
              <p>
                Em outubro de 2017, após as decisões acordadas em audiência de
                conciliação vinculada à Ação Civil Pública nº. 0400.15.004335-6,
                da 2ª Promotoria de Justiça da Comarca de Mariana-MG, o cadastro
                até então realizado pelas empresas foi suspenso, e a Cáritas
                Brasileira Regional Minas Gerais assumiu a coordenação da
                reestruturação do formulário e dos instrumentos complementares e
                da posterior execução do processo de cadastramento dos atingidos
                e das atingidas do município. Algo inédito em toda a bacia do
                rio Doce após o colapso de Fundão, foi homologado em audiência
                judicial que o cadastro seria amplo e aberto e, portanto, as
                empresas causadoras dos danos não poderiam ser as responsáveis
                por identificar quem fora atingido ou não, o que determinou a
                necessidade desse cadastro se pautar no Princípio da
                Autodeclaração.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/contexto_conquista_01.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Equipe de assessores do Cadastro da Cáritas. Foto: Acervo Cáritas,
            2018.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                A reformulação do cadastro pela
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_assessoria_tecnica' }"
                  >Assessoria Técnica</router-link
                >
                da
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_caritas_regional' }"
                  >Cáritas Brasileira Regional Minas Gerais</router-link
                >
                teve como objetivo sistematizar, quantificar e qualificar os
                danos materiais, patrimoniais, coletivos e imateriais suportados
                pelas pessoas atingidas. A metodologia implementada pela Cáritas
                buscou o aperfeiçoamento dos instrumentos complementares ao
                formulário, como a Cartografia Social e a Tomada de Termo, além
                de um diagnóstico socioeconômico e cultural das perdas dos
                atingidos e atingidas da região de Mariana-MG, para buscar,
                assim, construir um modelo de cadastro que abarcasse as
                diferentes realidades das pessoas atingidas.
              </p>
              <p>
                A Cáritas, escolhida como a instituição que executaria o
                cadastro no município de Mariana-MG, deu início ao processo de
                cadastramento dos estimados 500 núcleos familiares em fevereiro
                de 2018. Entretanto, em abril do mesmo ano, o número de núcleos
                interessados já havia duplicado devido à ampliação do
                autorreconhecimento dos danos sofridos em razão do desastre
                criminoso.
              </p>
              <p>
                Importante considerar que o reconhecer-se atingido foi
                processual e, por conseguinte, a reivindicação desse lugar e dos
                direitos a serem reparados também o foi. De forma abrupta, no
                município de Mariana-MG centenas de famílias tornaram-se
                “atingidas” a partir de um verdadeiro mar de lama tóxica que
                entrou em suas vidas. Gradativamente, vários moradores das
                comunidades situadas no entorno do território do Rio Gualaxo do
                Norte, assim como agricultores e pessoas que ali realizavam
                atividades produtivas diversas, depararam-se com mudanças,
                restrições, privações, perdas, danos e impedimentos impostos
                pelo rompimento da barragem e, assim, passaram a reconhecerem-se
                também na condição de atingidos e atingidas, reivindicando o
                registro em seus respectivos processos de cadastramento.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/contexto_conquista_02.png" alt="" />
          <p style="font-size: 0.5em">
            Assembleia da Comissão dos atingidos pelo rompimento da barragem de
            Fundão. Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_reformulacao_cadastro' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer "Formulário"
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";

import FlagTitle from "../../components/FlagTitle.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    FlagTitle,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Contexto e conquista",
          href: "sobre_contexto_conquistas",
        },
      ],
    };
  },
};
</script>

<style scoped>
h5 {
  color: #c92f05;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}
</style>
